import "../../styles/pages/capital.scss";
import "../../styles/pages/our-business.scss";
import "../../styles/pages/press.scss";

import React, { useRef, useState } from "react";

import Layout from "../../components/Layout";
import { Link } from "gatsby";
import PressTeaser from "../../components/PressTeaser";
import ResponsiveSource from "../../components/ResponsiveSource";
import SEO from "../../components/SEO";
import ScrollTranslate from "../../components/ScrollTranslate";
import SnapScroll from "../../components/SnapScroll";
import SnapScrollParallaxLayer from "../../components/SnapScrollParallaxLayer";
import classNames from "classnames";

/**
 *
 */
function Capital({ pageContext }) {
  const { seo } = pageContext;
  const { title, metaDesc } = seo;
  const capitalData = pageContext;

  const bodyData = capitalData?.pageTemplateCapital;
  const businessData =
    capitalData?.pageTemplateCapital?.ourBusiness?.businessItem || [];
  const items = capitalData?.allWpPressPost?.nodes || [];

  const [hideLinks, setHideLinks] = useState(true);

  const scrollRef = useRef(null);
  const wheelRef = useRef(null);

  const sortedItems = items.sort(function (a, b) {
    return (
      new Date(b?.datePublished).valueOf() -
      new Date(a?.datePublished).valueOf()
    );
  });

  return (
    <Layout className="Capital" hideLinks={hideLinks} links={true} theme="dark">
      <SEO title={title} description={metaDesc} />
      <SnapScroll setHideLinks={setHideLinks}>
        <article className="CapitalIntro SnapScroll-itemInner">
          <SnapScrollParallaxLayer speed={0.5}>
            <header className="CapitalIntro-header">
              {bodyData?.heading && (
                <h1 className="CapitalIntro-itemTitle">{bodyData?.heading}</h1>
              )}
            </header>
          </SnapScrollParallaxLayer>
        </article>
        <article className="OurBusiness SnapScroll-itemInner" id="our-business">
          <SnapScrollParallaxLayer speed={0.25}>
            <header className="OurBusiness-header">
              {bodyData?.ourBusiness?.heading && (
                <h2 className="OurBusiness-headerHeading">
                  {bodyData?.ourBusiness?.heading}
                </h2>
              )}
              <p className="OurBusiness-headerText">
                {bodyData?.ourBusiness?.body}
              </p>
            </header>
          </SnapScrollParallaxLayer>
          <SnapScrollParallaxLayer speed={0.5}>
            <div className="OurBusiness-main">
              <ul className="OurBusiness-list">
                {businessData.map((businessItem, i) => (
                  <li key={i} className="OurBusiness-item">
                    <div className="OurBusiness-block">
                      <Link
                        className="OurBusiness-blockText"
                        to={businessItem?.itemLink?.url}
                      >
                        {businessItem?.itemLink?.title}
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </SnapScrollParallaxLayer>
        </article>
        <article
          className="Press SnapScroll-itemInner"
          id="press"
          ref={wheelRef}
        >
          <SnapScrollParallaxLayer speed={0.25}>
            <header className="Press-header">
              <h1 className="Press-headerHeading">{bodyData?.postHeading}</h1>
            </header>
          </SnapScrollParallaxLayer>
          <SnapScrollParallaxLayer speed={0.5}>
            <div className="Press-main">
              <div className="Press-listContainer">
                <ScrollTranslate
                  className="Press-list"
                  ref={scrollRef}
                  wheelRef={wheelRef}
                >
                  {sortedItems.map((item, i) => (
                    <div
                      className={classNames("Press-item", {
                        [`Press-item--${item?.postTemplatePress?.pressPost?.variant}`]:
                          item?.postTemplatePress?.pressPost?.variant,
                      })}
                      key={i}
                    >
                      <PressTeaser data={item} />
                    </div>
                  ))}
                </ScrollTranslate>
              </div>
            </div>
          </SnapScrollParallaxLayer>
        </article>
      </SnapScroll>
      <video autoPlay className="Capital-video" loop muted playsInline>
        <ResponsiveSource
          sourceDesktop={bodyData?.capitalVideoBackgroundDesktop}
          sourceMobile={bodyData?.capitalVideoBackgroundMobile}
          sourceTablet={bodyData?.capitalVideoBackgroundTablet}
        />
      </video>
    </Layout>
  );
}

export default Capital;

// const capitalData = useStaticQuery(graphql`
// query {
//   wpPage(slug: { eq: "capital" }) {
//     pageTemplateCapital {
//       capitalVideoBackgroundDesktop
//       capitalVideoBackgroundTablet
//       capitalVideoBackgroundMobile
//       heading
//       ourBusiness {
//         heading
//         body
//         businessItem {
//           itemLink {
//             url
//             title
//           }
//         }
//       }
//       postHeading
//     }
//   }
// allWpPressPost {
//   nodes {
//     title
//     postTemplatePress {
//       pressPost {
//         image {
//           altText
//           sourceUrl
//         }
//         mediaType
//         variant
//         vimeoId
//         datePublished
//         bodyCopy
//         pageLink
//       }
//     }
//   }
// }
// }
// `);
