import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

SnapScrollParallaxLayer.propTypes = {
  children: PropTypes.node.isRequired,
  speed: PropTypes.number,
};

interface SnapScrollParallaxLayerProps {
  children: React.ReactNode;
  speed: number;
}

/**
 * @param props
 * @param props.children
 * @param props.speed
 */
function SnapScrollParallaxLayer({
  children,
  speed = 1,
}: SnapScrollParallaxLayerProps) {
  const ref = useRef(null);
  let parent: null | any = null;

  const [offset, setOffset] = useState(0);

  /**
   * @param e
   */
  function handleScroll() {
    if (ref?.current) {
      const left = ref.current.getBoundingClientRect().left;
      const newOffset = left - ref.current.offsetLeft;
      setOffset(newOffset * speed);
    }
  }

  useEffect(() => {
    parent = document.querySelector(".SnapScroll-list");
    parent.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      parent.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, []);

  return (
    <div ref={ref}>
      <div
        style={{
          transform: `translateX(${offset}px)`,
          willChange: "transform",
        }}
      >
        {children}
      </div>
    </div>
  );
}

SnapScrollParallaxLayer.propTypes = {
  children: PropTypes.node.isRequired,
  speed: PropTypes.number,
};

SnapScrollParallaxLayer.defaultProps = {
  speed: 1,
};

export default SnapScrollParallaxLayer;
