import "../styles/components/snap-scroll.scss";

import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

interface SnapScrollProps {
  children: React.ReactNode,
  setHideLinks: (x: boolean) => void,
}

/**
 * @param props
 * @param props.children
 * @param props.setHideLinks
 */
const SnapScroll = ({ children, setHideLinks }: SnapScrollProps) => {
  const [activeItem, setActiveItem] = useState(0);

  const listRef = useRef(null);

  /**
   * @param e
   */
  function handleKeydown(e) {
    switch (e.keyCode) {
      case 37:
        previous();
        break;
      case 39:
        next();
        break;
    }
  }

  /**
   *
   */
  function handleScroll() {
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const i = Math.round(
      listRef.current.scrollLeft / (viewportWidth * (children.length - 2))
    );
    if (activeItem !== i) setActiveItem(i);
  }

  /**
   * @param e
   */
  function handleWheel(e) {
    if (e.deltaY > 40) {
      next();
    } else if (e.deltaY < -40) {
      previous();
    }
  }

  /**
   * @param i
   */
  function scrollTo(i) {
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const left = viewportWidth * i;
    listRef.current.scrollTo({ behavior: "smooth", left });
  }

  /**
   *
   */
  function next() {
    if (activeItem === children.length - 1) return;
    scrollTo(activeItem + 1);
  }

  /**
   *
   */
  function previous() {
    if (activeItem === 0) return;
    scrollTo(activeItem - 1);
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, { passive: false });
    setHideLinks(activeItem !== 0);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [activeItem]);

  return (
    <div className="SnapScroll">
      <div
        className="SnapScroll-list"
        onScroll={handleScroll}
        onWheel={handleWheel}
        ref={listRef}
      >
        {children.map((child, i) => (
          <div className="SnapScroll-item" key={i}>
            {child}
          </div>
        ))}
      </div>
      <ul className="SnapScroll-dotList">
        {children.map((child, i) => (
          <li
            className={classNames("SnapScroll-dotItem", {
              "SnapScroll-dotItem--active": activeItem === i,
            })}
            key={i}
          >
            <button
              className="SnapScroll-dotItem"
              onClick={() => scrollTo(i)}
            ></button>
          </li>
        ))}
      </ul>
    </div>
  );
};



export default SnapScroll;
