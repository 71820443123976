import "../styles/components/video-modal.scss";

import IconClose from "../icons/close.svg";
import React from "react";
import ReactPlayer from "react-player/vimeo";
import classNames from "classnames";

interface VideoModalProps {
  close: () => void;
  isOpen: boolean;
  vimeoId: string;
}

/**
 * @param props
 * @param props.close
 * @param props.isOpen
 * @param props.vimeoId
 */
function VideoModal({ close, isOpen, vimeoId }: VideoModalProps) {
  return (
    <div className={classNames("VideoModal", { "VideoModal--open": isOpen })}>
      <div className="VideoModal-inner">
        <div className="VideoModal-playerContainer">
          <ReactPlayer
            controls={true}
            playing={isOpen}
            url={`https://vimeo.com/${vimeoId}`}
          />
        </div>
        <button className="VideoModal-close" onClick={() => close()}>
          <IconClose />
        </button>
      </div>
    </div>
  );
}

export default VideoModal;
