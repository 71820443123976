import "../styles/components/press-teaser.scss";

import React, { useState } from "react";

import ClampLines from "react-clamp-lines";
import IconPlay from "../icons/play.svg";
import { Portal } from "react-portal";
import VideoModal from "./VideoModal";
import classNames from "classnames";

interface PressTeaserProps {
  data: {
    title: string;
    postTemplatePress: {
      pressPost: {
        image: {
          altText: string;
          sourceUrl: string;
        };
        mediaType: string;
        variant: string;
        vimeoId: string;
        datePublished: string;
        bodyCopy: string;
        pageLink: string;
      };
    };
  };
}

/**
 * @param props
 * @param props.data
 */
function PressTeaser({ data }) {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  return data?.postTemplatePress?.pressPost?.vimeoId ? (
    <div
      className={classNames("PressTeaser", {
        [`PressTeaser--${data?.postTemplatePress?.pressPost?.variant}`]:
          data?.postTemplatePress?.pressPost?.variant,
        "PressTeaser--video": data?.postTemplatePress?.pressPost?.vimeoId,
      })}
    >
      {data?.postTemplatePress?.pressPost?.image && (
        <div className="PressTeaser-image PressTeaser-image--video">
          <img
            alt={data?.postTemplatePress?.pressPost?.image?.altText}
            src={data?.postTemplatePress?.pressPost?.image?.sourceUrl}
          />
        </div>
      )}
      <button
        className="PressTeaser-button"
        onClick={() => setVideoModalOpen(true)}
      >
        <IconPlay />
      </button>
      {data?.title && (
        <div className="PressTeaser-text">
          <h2 className="PressTeaser-title">{data?.title}</h2>
        </div>
      )}
      <Portal>
        <VideoModal
          close={() => setVideoModalOpen(false)}
          isOpen={isVideoModalOpen}
          vimeoId={data?.postTemplatePress?.pressPost?.vimeoId}
        />
      </Portal>
    </div>
  ) : (
    <div
      className={classNames("PressTeaser", {
        [`PressTeaser--${data?.postTemplatePress?.pressPost?.variant}`]:
          data?.postTemplatePress?.pressPost?.variant,
      })}
    >
      {data?.postTemplatePress?.pressPost?.image && (
        <div className="PressTeaser-image">
          <picture>
            <source
              media="(min-width: 720px)"
              srcSet={data?.postTemplatePress?.pressPost?.image?.sourceUrl}
            />
            <img
              alt={data?.postTemplatePress?.pressPost?.image?.altText}
              src={data?.postTemplatePress?.pressPost?.image?.sourceUrl}
            />
          </picture>
        </div>
      )}
      <div className="PressTeaser-text">
        <h2 className="PressTeaser-title">
          <ClampLines
            id="pressteaser-title"
            buttons={false}
            ellipsis="…"
            lines={4}
            text={data?.title}
          />
        </h2>
        {data?.postTemplatePress?.pressPost?.datePublished && (
          <div className="PressTeaser-date">
            {data?.postTemplatePress?.pressPost?.datePublished}
          </div>
        )}
        {data?.postTemplatePress?.pressPost?.bodyCopy && (
          <div className="PressTeaser-excerpt">
            <ClampLines
              buttons={false}
              ellipsis="…"
              lines={4}
              text={data?.postTemplatePress?.pressPost?.bodyCopy}
            />
          </div>
        )}
        {data?.postTemplatePress?.pressPost?.pageLink && (
          <div className="PressTeaser-link">
            <a
              href={data?.postTemplatePress?.pressPost?.pageLink}
              rel="noreferrer"
              target="_blank"
            >
              Read More
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default PressTeaser;
